import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["viewer", "source", "slidesWrapper", "slide"]
	static outlets = ["gallery"]

	connect() {
		this.slideTargets.forEach(template => {
			const slide = template.content.cloneNode(true)

			this.slidesWrapperTarget.appendChild(slide)
		})
	}

	show({ params }) {
		document.body.classList.add("overflow-hidden")

		this.viewerTarget.dataset.active = true

		const mediaId = params.id
		const index = this.slideTargets.map(slide => slide.dataset.id).indexOf(mediaId)

		this.galleryOutlet.swiper.slideToLoop(index, 0)
	}

	hide() {
		document.body.classList.remove("overflow-hidden")

		this.viewerTarget.dataset.active = false
	}
}
