import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["image", "input", "deleteButton"]
	static classes = ["hidden"]

	update({ target }) {
		this.imageTarget.src = URL.createObjectURL(target.files[0])
		this.imageTarget.classList.remove(this.hiddenClass)

		this.deleteButtonTarget.classList.remove(this.hiddenClass)
	}

	remove() {
		this.imageTarget.src = ""
		this.imageTarget.classList.add(this.hiddenClass)

		this.deleteButtonTarget.classList.add(this.hiddenClass)
	}

	imageTargetConnected(target) {
		if (target.src != "") { target.classList.remove(this.hiddenClass) }
	}
}
