import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js"

export default class extends Controller {
	static targets = ["list"]

	connect() {
		this.sortable = Sortable.create(this.listTarget, {
			animation: 150,
			handle: ".sortable-handle",
			ghostClass: "opacity-50",
			onEnd: this.onEnd.bind(this)
		})
	}

	disconnect() {
		this.sortable.destroy()
	}

	onEnd(event) {
		const { newIndex, item } = event
		const url = item.dataset.sortableUrl

		put(url, {
			contentType: "application/json",
			responseKind: "json",
			body: { position: newIndex + 1 }
		})
	}
}
