import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Autoplay } from "swiper/modules"

export default class extends Controller {
	static targets = ["slider"]

  connect() {
		this.swiper = new Swiper(this.sliderTarget, {
			modules: [ Autoplay ],
			loop: true,
			grabCursor: false,
			speed: 750,
			longSwipesMs: 100,
			longSwipesRatio: 0.2,
			autoplay: {
				delay: 5000,
				disableOnInteraction: true
			}
		})
  }
}
