import { Application } from "@hotwired/stimulus"
import RailsNestedForm from "@stimulus-components/rails-nested-form"
import Dropdown from "@stimulus-components/dropdown"

const application = Application.start()
application.register("admin--nested-form", RailsNestedForm)
application.register("admin--dropdown", Dropdown)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
