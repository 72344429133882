import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
	connect() {
		this.slimSelect = new SlimSelect({
			select: this.element,
			settings: {
				hideSelected: true,
				closeOnSelect: false
			}
		})
	}

	disconnect() {
		this.slimSelect.destroy()
	}
}
