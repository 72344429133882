import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		url: String
	}

	refresh() {
		Turbo.visit(this.urlValue.replace("xyz", this.element.value))
	}
}
