import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { FreeMode, Mousewheel } from "swiper/modules"

export default class extends Controller {
	static targets = ["slider"]

	static values = {
		reversed: {
			type: Boolean,
			default: false
		}
	}

	connect() {
		this.swiper = new Swiper(this.sliderTarget, {
			modules: [FreeMode, Mousewheel],
			loop: true,
			slidesPerView: 2,
			centeredSlides: true,
			spaceBetween: 24,
			grabCursor: true,
			freeMode: true,
			mousewheel: {
				enabled: true,
				eventsTarget: ":root",
				invert: this.reversedValue
			},
			speed: 8000,
			breakpoints: {
				768: {
					slidesPerView: 3,
					allowTouchMove: false,
				},
				1280: {
					slidesPerView: 4,
					allowTouchMove: false,
				}
			}
		})
	}
}

