import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

export default class extends Controller {
	static values = {
		url: String
	}

	update() {
		put(this.urlValue, {
			contentType: "application/json",
			responseKind: "json",
			body: { status: this.element.value }
		})
	}
}
