import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["mobileNav"]

	static values = {
		mobileMenuOpened: Boolean
	}

	connect() {
		setTimeout(() => this.mobileNavTargets.forEach(nav => nav.classList.add("header__mobile_transition")), 1000)
	}

	toggleMobileMenu() {
		this.mobileMenuOpenedValue = !this.mobileMenuOpenedValue
	}

	mobileMenuOpenedValueChanged(value) {
		if (value) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	}
}
