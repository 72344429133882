import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Mousewheel } from "swiper/modules"

export default class extends Controller {
	connect() {
		this.swiper = new Swiper(this.element, {
			modules: [ Mousewheel ],
			slidesPerView: 1.2,
			grabCursor: true,
			autoHeight: true,
			slideToClickedSlide: true,
			breakpoints: {
				640: {
					slidesPerView: 1.5,
					autoHeight: false,
				},
				1280: {
					slidesPerView: 2.5,
					autoHeight: false,
				}
			}
		})
	}
}
