import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"

export default class extends Controller {
	static targets = [
		"slider",
		"pagination",
		"next",
		"prev",
		"slideTitle",
		"slideAttribution"
	]

	connect() {
		this.swiper = new Swiper(this.sliderTarget, {
			loop: true,
			modules: [ Navigation, Pagination ],
			grabCursor: true,
			speed: 750,
			longSwipesMs: 100,
			longSwipesRatio: 0.2,
			navigation: {
				prevEl: this.prevTarget,
				nextEl: this.nextTarget
			},
			pagination: {
				el: this.paginationTarget,
				type: "fraction"
			},
			on: {
				slideChange: () => {
					if (!this.swiper) return

					const slide = this.swiper.slides[this.swiper.activeIndex]
					const mediaInfo = slide.querySelector("media-info").dataset

					this.slideTitleTarget.innerText = mediaInfo.title
					this.slideAttributionTarget.innerText = mediaInfo.attribution
				}
			}
		})
	}
}
