import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Navigation } from "swiper/modules"

export default class extends Controller {
	static targets = ["slider", "nextButton", "prevButton"]

	connect() {
		this.slider = new Swiper(this.sliderTarget, {
			modules: [ Navigation ],
			loop: false,
			navigation: {
				nextEl: this.nextButtonTarget,
				prevEl: this.prevButtonTarget
			},
			spaceBetween: 24,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 4,
				}
			}
		})
	}
}
