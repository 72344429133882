import { Controller } from "@hotwired/stimulus"
import waterfall from "../helpers/waterfall"

export default class extends Controller {
	static targets = ["container"]

	connect() {
		waterfall(this.containerTarget)
	}

	update() {
		waterfall(this.containerTarget)
	}
}
