import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["kind", "internalLink", "externalLink", "attachment"]
	static classes = ["hidden"]

	connect() {
		this.setupFields()
	}

	setupFields() {
		switch (this.kindTarget.value) {
			case "internal_link":
				this.showField(this.internalLinkTarget)
				this.hideField(this.externalLinkTarget)
				this.hideField(this.attachmentTarget)
				break
			case "external_link":
				this.hideField(this.internalLinkTarget)
				this.showField(this.externalLinkTarget)
				this.hideField(this.attachmentTarget)
				break
			case "attachment":
				this.hideField(this.internalLinkTarget)
				this.hideField(this.externalLinkTarget)
				this.showField(this.attachmentTarget)
				break
		}
	}

	showField(field) {
		field.disabled = false
		field.classList.remove(this.hiddenClass)
	}

	hideField(field) {
		field.disabled = true
		field.classList.add(this.hiddenClass)
	}
}
